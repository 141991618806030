<template>
    <!--nav-->
    <nav class="navbar navbar-default no-margin app-sidebar-nav">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header fixed-brand">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" id="menu-toggle">
                <span class="glyphicon glyphicon-th-large" aria-hidden="true"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)"><img src="/static/images/logo.webp" alt="Velocity Vehicle Group" width="161" height="32"></a>
        </div>
        <!-- navbar-header-->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav">
                <li class="active">
                    <button class="navbar-toggle collapse in" data-toggle="collapse" id="menu-toggle-2"> 
                        <img src="/static/images/menubar.png" alt="Menu" class="menubar" width="42" height="23">
                    </button>
                </li>
            </ul>
        </div>
        <!--notification-->
        <!--<div class="notification">
            <a href="#">
                <img src="/static/images/notification-bell.png" alt="Notification">
            </a>
        </div>-->
        <!--notification-->

        <!--Common Page Global Search-->
        <div id="wrap">
            <form action="" autocomplete="on" v-on:submit.prevent="redirectToGlobalSearch()">
                <input v-model="searchText" id="search" name="search" type="text" placeholder="Search..." style="margin-right: 120px">
                <input id="search_submit" value="search" type="submit" style="margin-right: 120px">
            </form>
        </div>

        <!--userinfo-->
        <div class="user-info">
            <div class="user-info-container">
                <div class="dropdown">
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                        <div class="dropdown-user">
                            <!--<div class="image"><img :src="image" alt="User" width="48" height="48"></div>-->
                            <div class="username-letter">{{loggedin_user_img}}</div>
                            <div class="dropdown-user-right">
                                <div class="username">{{formatString(loggedin_user)}} {{formatString(loggedin_user_last_name)}}</div>
                                <br/>
                                <div class="usermailid">{{email}}</div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-menu">
                        <!--<a class="dropdown-item" href="#">My Profile</a>-->
                        <router-link v-if="user_role == 1" class="dropdown-item"  v-bind:to="{name: 'AddEditMenu'}">Edit Menu</router-link>
                        <!--<a class="dropdown-item" href="#">Inbox</a>
                        <a class="dropdown-item" href="#">Account Setting</a>-->
                        <router-link v-if="user_role == 1" class="dropdown-item"  v-bind:to="{name: 'UserList'}">User</router-link>
                        <router-link v-if="user_role == 1" class="dropdown-item"  v-bind:to="{name: 'ListCalendarKey'}">Calendar Keys</router-link>
                        <router-link v-if="user_role == 1" class="dropdown-item"  v-bind:to="{name: 'ListAttachmentGroup'}">Attachment Group</router-link>
                        <a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="logout">Logout</a>
                    </div>
                </div>
            </div>
        </div>
        <!--userinfo-->
        <!-- bs-example-navbar-collapse-1 -->
    </nav>
    <!--nav-->
</template>

<script>

    import EventBus from '.././event-bus';
    var contex;
    import {commonMixins} from '../../mixins/commonMixins';

    export default {
        name: "Header",
        mixins: [commonMixins],
        data: () => ({
                profileColor: "",
                isShowLoggedinfields: false,
                loggedin_user_img: '',
                loggedin_user: '',
                loggedin_user_last_name: '',
                role: "",
                email: "",
                image: "/static/images/default.jpg",
                searchText : "",
            }),
        beforeMount: function () {
            contex = this;
        },
        mounted: function () {
            let userObj = localStorage.getItem('user_obj');
            if (userObj && userObj != null) {
                let profileColor = localStorage.getItem('profileColor');
                if (profileColor && profileColor != null) {
                    contex.profileColor = profileColor;
                } else {
                    contex.profileColor = contex.gerRandomColor();
                    localStorage.setItem("profileColor", contex.profileColor);
                }
                if (contex.profileColor != "") {
                    /*$(".username-letter").css({"background": contex.profileColor});*/
                }
                contex.$store.dispatch('loadMenu');

                let location = window.location.pathname;
                if (location.includes("/globalsearch/")) {
                    const afterGlobalSearch = location.substring(location.indexOf("/globalsearch/") + 14); // +14 to skip "/globalsearch/"
                    contex.searchText = afterGlobalSearch;

                    contex.$router.replace({ name: 'GlobalSearchView', params: {searchText: contex.searchText }}).catch(()=>{});
                }
            }
            //contex.$store.dispatch('getRole');
        },
        methods: {

            redirectToGlobalSearch : function() {
                let location = window.location.pathname;
                if(contex.searchText != '' && typeof contex.searchText !== 'undefined' && contex.searchText != null) {
                    if (contex.searchText.length >= 2) {
                        if (location === "/globalsearch") {
                            contex.$router.replace({ name: 'GlobalSearchView', params: {searchText: contex.searchText }}).catch(()=>{});
                            // EventBus.$emit("on-globalsearch", contex.searchText);
                            // window.location.href = "/globalsearch";
                        } else {
                            contex.$router.push({ name: 'GlobalSearchView', params: {searchText: contex.searchText }}).catch(()=>{});
                        }
                    }
                }
            },

            logout: function () {
                //clear all local storage
                let current_build = window.localStorage.getItem("build_version");
                localStorage.clear();
                localStorage.setItem("build_version", current_build);
                // window.location.href = "/";
                //contex.$router.push("/");

                // Auto-logout Current Project From SSO
                window.location.href = process.env.VUE_APP_SSO_ROOT_URL + "/logout?redirect_url=" + process.env.VUE_APP_FRONT_ROOT_URL + '&slug=' + process.env.VUE_APP_PROJECT_SLUG;
            },

            formatString: function (data) {
                let value = data.replace(/_/g, " ");
                let upp = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                });
                return upp.toUpperCase();
            },

        },

    };

    EventBus.$on('logged-in', function (isLogin, name, lastName, email,username) {
        if (typeof (contex) != "undefined") {
            contex.isShowLoggedinfields = isLogin;
            contex.loggedin_user = name.trim();
            contex.loggedin_user_last_name = lastName.trim();
            contex.loggedin_user_img = (contex.loggedin_user.charAt(0) + contex.loggedin_user_last_name.charAt(0)).toUpperCase();
            contex.email = email;
            if (contex.loggedin_user_img == "") {
                contex.loggedin_user_img = (contex.email.charAt(0) + contex.email.charAt(1)).toUpperCase();
            }
            //contex.role = role;
            //contex.image = "/static/images/default.jpg";

            /* if (file_path != null){
            contex.image = file_path;
            }else{
            contex.image = "/static/images/default.jpg";
            } */
        }
    });

    EventBus.$on('on-globalsearch', function () {
        contex.searchText = "";
    })
</script>