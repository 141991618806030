<template>
    <!--nav-->
    <nav class="navbar navbar-default no-margin app-sidebar-nav">

        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header fixed-brand">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" id="menu-toggle" v-on:click.prevent="menuTtoggleMobile(sidebarType)">
                <span class="glyphicon glyphicon-th-large" aria-hidden="true"></span>
            </button>
            <router-link v-bind:to="{ path:'/' + routerSlug}" class="navbar-brand">
                <img src="/static/images/logo.png" alt="Velocity Vehicle Group">
            </router-link>
            <button type="button" title='Download Contact Extension Directory' v-on:click.prevent="downloadCntExtDirectory()" class="downloadDirectory btn btn-primary waves-effect">
                <i class="fa fa-download" aria-hidden="true"></i>
            </button>
        </div>
        <!-- navbar-header-->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav">
                <li class="active">
                    <button class="navbar-toggle collapse in" data-toggle="collapse" id="menu-toggle-2" v-on:click.prevent="menuTtoggleDesktop(sidebarType)"> 
                        <img src="/static/images/menubar.png" alt="Menu" class="menubar">
                    </button>
                </li>
            </ul>
        </div>
        <!--notification-->
        <!--<div class="notification">
            <a href="#">
                <img src="/static/images/notification-bell.png" alt="Notification">
            </a>
        </div>-->
        <!--notification-->

        <!--Contact Extension Global Search-->
        <div id="wrap">
            <form action="" autocomplete="on" v-on:submit.prevent="universalSearchAPI()">
                <input v-model="universalSearchText" id="universalSearch" name="universalSearch" type="text" placeholder="Search..." style="margin-right: 120px">
                <input id="search_submit" value="universalSearch" type="submit" style="margin-right: 120px">
            </form>
        </div>

        <!--userinfo-->
        <div class="user-info">
            <div class="user-info-container">
                <div class="dropdown">
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                        <div class="dropdown-user">
                            <!--<div class="image"><img :src="image" alt="User" width="48" height="48"></div>-->
                            <div class="username-letter">{{loggedin_user_img}}</div>
                            <div class="dropdown-user-right">
                                <div class="username">{{formatString(loggedin_user)}} {{formatString(loggedin_user_last_name)}}</div>
                                <br/>
                                <div class="usermailid">{{email}}</div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="logout">Logout</a>
                    </div>
                </div>
            </div>
        </div>
        <!--userinfo-->
        <!-- bs-example-navbar-collapse-1 -->
    </nav>
    <!--nav-->
</template>

<script>
var self;
import Vue from "vue";
import EventBus from '.././event-bus';
import {commonMixins} from '../../mixins/commonMixins';
import { HTTP } from '../../http-common.js';

export default {
    name: 'ExtensionHeader',
    mixins: [commonMixins],
    data() {
        return {
            profileColor: "",
            isShowLoggedinfields: false,
            loggedin_user_img: '',
            loggedin_user: '',
            loggedin_user_last_name: '',
            role: "",
            email: "",
            image: "/static/images/default.jpg",
            universalSearchText : "",
            sidebarType: "",
            permissionArray : [],
            routerSlug: ''
        }
    },
    beforeMount: function () {
        self = this;
    },
    mounted: function () {
        var self = this;
        var userObj = localStorage.getItem('user_obj');
        if (userObj && userObj != null) {
            var profileColor = localStorage.getItem('profileColor');
            if (profileColor && profileColor != null) {
                self.profileColor = profileColor;
            } else {
                self.profileColor = self.gerRandomColor();
                localStorage.setItem("profileColor", self.profileColor);
            }
            if (self.profileColor != "") {
                /*$(".username-letter").css({"background": self.profileColor});*/
            }
        }
        //self.$store.dispatch('getRole');
        self.getFirstMenu();
    },
    methods: {
        getFirstMenu: function () {
            var self = this;
            HTTP.get("get_first_menu", [])
                .then(function (response) {
                    if (response.data.code == 200) {
                        //console.log(JSON.stringify(response.data.content.slug));
                        self.routerSlug = response.data.content.slug;
                    }
                })
                .catch(function (err) {
                    //console.log(err);
                    self.catchHandler(err, function () {

                    });
                });
        },
        universalSearchAPI: function () {
            var location = window.location.pathname;
            if (self.universalSearchText.length > 2) {
                if (location === "/directory/extensionslist/globalsearch") {
                    self.$router.replace({ name: 'GlobalSearchView', params: {searchText: self.universalSearchText }}).catch(()=>{});
                    // EventBus.$emit("on-globalsearch", self.searchText);
                    // window.location.href = "/globalsearch";
                } else {
                    self.$router.push({ name: 'GlobalSearchView', params: {searchText: self.universalSearchText }}).catch(()=>{});
                }
            }

            // self.$router.push("/directory/extensionslist/" + self.universalSearchText);
        },

        logout: function () {
            //clear all local storage
            let current_build = window.localStorage.getItem("build_version");
            localStorage.clear();
            localStorage.setItem("build_version", current_build);
            // window.location.href = "/";
            //self.$router.push("/");

            // Auto-logout Current Project From SSO
            window.location.href = process.env.VUE_APP_SSO_ROOT_URL + "/logout?redirect_url=" + process.env.VUE_APP_FRONT_ROOT_URL + '&slug=' + process.env.VUE_APP_PROJECT_SLUG;
        },

        formatString: function (data) {
            var self = this;
            var value = data.replace(/_/g, " ");
            var upp = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase();
            });
            return upp.toUpperCase();
        },

        downloadCntExtDirectory: function () {
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("tkn")},
            };
            self.showLoader();
            HTTP.get('ce-cntExtDirectoryDwnld', config)
                    .then(function (response) {
                        if (response.data.code == '200') {
                            location.href = response.data.content.web;
                        }
                        self.hideLoader();
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                        self.hideLoader();
                    })
        },
    },
}
EventBus.$on("permissions", function(isLogin, permissionArray) {
    if (typeof self != "undefined") {
        self.permissionsArray = [];
        if(permissionArray && permissionArray.length > 0) {
            for (var i = 0; i < permissionArray.length; i++) {
                self.permissionsArray.push(permissionArray[i].name);
            }
        }
    }
});
EventBus.$on('logged-in', function (isLogin, name, lastName, email,username) {
    if (typeof (self) != "undefined") {
        self.isShowLoggedinfields = isLogin;
        self.loggedin_user = name.trim();
        self.loggedin_user_last_name = lastName.trim();
        self.loggedin_user_img = (self.loggedin_user.charAt(0) + self.loggedin_user_last_name.charAt(0)).toUpperCase();
        self.email = email;
        if (self.loggedin_user_img == "") {
            self.loggedin_user_img = (self.email.charAt(0) + self.email.charAt(1)).toUpperCase();
        }
        //self.role = role;
        //self.image = "/static/images/default.jpg";

        /* if (file_path != null){
            self.image = file_path;
            }else{
            self.image = "/static/images/default.jpg";
            } */
    }
});
EventBus.$on('on-globalsearch', function (data) {
    self.universalSearchText = data;
});
EventBus.$on('sidebarType', function (data) {
    if (typeof (self) != "undefined") {
        self.sidebarType = data;
    }
});
</script>