// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import 'core-js/es6/number';
// import 'core-js/shim';

import Vue from 'vue';
import App from './App';
import router from './router';
import VueRouter from 'vue-router'
import { store } from './store/store';

import * as Sentry from "@sentry/vue";

/*Bootstrap Vue start */
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
/*Bootstrap Vue End */

import linkify from 'vue-linkify';
Vue.directive('linkified', linkify);

import VeeValidate from 'vee-validate';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

import datePicker from 'vue-bootstrap-datetimepicker';
Vue.component('datePicker', datePicker);

Vue.use(VueAxios, axios);
Vue.use(VeeValidate);
Vue.use(VueRouter);

Vue.config.productionTip = false;

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracing: true,
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    trackComponents: ["Header", "Navigation", "Footer"],
    hooks: ["create", "mount"],
  });

/* eslint-disable no-new */
var vm = new Vue({
    el: '#app',
    router,
    store,
    // components: {App},
    render: h => h(App),
    template: '<App/>'
});