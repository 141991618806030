import axios from 'axios';

export const HTTP = axios.create({

    baseURL: process.env.VUE_APP_ROOT_API, //http://vvg.salesintranet.com/api/v1
    headers: {
      'Content-Type': 'application/json',
    }
});
export const HTTPGUZZLE = axios.create({

    // baseURL: "https://www.velocitytruckcenters.com",
    // baseURL: "http://velocitytruckcenters.local.com",
    baseURL: process.env.VUE_APP_SSO_URL,
    headers: {
      'Content-Type': 'application/json',
    }
});
HTTPGUZZLE.interceptors.response.use(function (response) {
  // We have removed Wilmington, NC location requested by April Sheppard and We have added branch wise Wilmington location added in VTC Website.
  if(response.data.locations && response.data.locations.length > 0) {
    for(var index = 0; index < response.data.locations.length; index++) {
      var objLocation = response.data.locations[index];
      if(objLocation['name'] == 'Wilmington, NC' || objLocation['id'] == 92) {
        response.data.locations.splice(index, 1);
      }
    }
    var objWilmington372 = {
      "name": "Wilmington 372, NC",
      "id": 1004,
      "position": {
        "lat": 34.225727,
        "lng": -77.944710
      },
      "is_buswest": false,
      "manager_email": "dbrant@vvgtruck.com",
      "manager_name": "Deanne Brant"
    };
    // response.data.locations.push(objWilmington372);
    var objWilmington373 = {
      "name": "Wilmington 373, NC",
      "id": 1005,
      "position": {
        "lat": 34.225727,
        "lng": -77.944710
      },
      "is_buswest": false,
      "manager_email": "dbrant@vvgtruck.com",
      "manager_name": "Deanne Brant"
    };
    // response.data.locations.push(objWilmington373);
    var objFlagstaff330 = {
      "name": "Flagstaff, AZ",
      "id": 1006,
      "position": {
        "lat": 35.21677,
        "lng": -111.58591
      },
      "is_buswest": false,
      "manager_email": "cblair@vvgtruck.com",
      "manager_name": "Carrie Blair"
    };
    // response.data.locations.push(objFlagstaff330);
  }
  return response;
});