import Vue from 'vue';
import Vuex from 'vuex';

import {HTTP} from '../http-common';
import EventBus from "../components/event-bus";
import {commonMixins} from '../mixins/commonMixins';

Vue.use(Vuex);

export const store = new Vuex.Store({
    mixins: commonMixins,
    state: {
        menuArr: [
            {
                menu_title : '',
            }
        ],
        roles: 0,
        past_tab: 0,
        cntExtMenuDataArr : []
    },
    mutations: {
        changeMenuData(state, menuArr){
            state.menuArr = menuArr;
        },
        changeUserRole(state, roles) {
            state.roles = roles;
        },
        changePastTab(state, tab) {
            state.past_tab = tab;
        },
        changeCntExtMenuData(state, cntExtMenuDataArr) {
            state.cntExtMenuDataArr = cntExtMenuDataArr;
        }
    },
    actions: {
        loadMenu: function (context) {
            var self = context;

            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tkn")
                }
            };

            HTTP.get("get_menu_details", config)
                    .then(function (response) {

                        if (response.data.code == 200) {
                            self.menuArr = response.data.content.menu_details;
                            self.commit('changeMenuData', self.menuArr);
                            self.commit('changeUserRole', response.data.content.roles.id);
                            EventBus.$emit("systemYear", true, response.data.content.system_year);
                            EventBus.$emit("reload-menu", self.menuArr);
                            EventBus.$emit("user-role", response.data.content.roles.id);
                            
                            // Getting User Roles with Menu Details
                            localStorage.setItem("user_role", response.data.content.roles.id);
                            self.systemYear = response.data.content.system_year;
                            //self.$store.commit('changeMenuData', self.menuArr)
                        }

                    }).catch(function (err) {
                        console.log(err);
                        self.catchHandler(err, function(){
                        }); 
                    });
        },

        loadCntExtMenuData: function (context) {
            var self = context;
            
            var config = {
                headers: { 
                    Authorization: "Bearer " + localStorage.getItem("tkn") 
                }
            };
            HTTP.get("ce-getcompanylocation", config)
                .then(function (response) {
                    if (response.data.code == 200) {
                        self.cntExtMenuDataArr = response.data.content;
                        self.commit('changeCntExtMenuData', self.cntExtMenuDataArr);
                        EventBus.$emit("cnt-ext-directory-menu", self.cntExtMenuDataArr);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    self.catchHandler(err, function () {});
                });
        },
    },
    getters: {
        menuArr: state => state.menuArr,
        roles: state => state.roles,
        past_tab: state => state.past_tab,
        cntExtMenuData: state => state.cntExtMenuDataArr
    }
});